import localeAntd from 'antd/es/locale/en_US'

const messages = {
  // *******************************Manage Orders Screen*******************************/

  'form.error.required': 'Reuired',
  'text.currentlyserving': 'Currently Serving',
  'text.totalordervalue': 'Total Open Orders Value',
  'text.users': 'Users',
  'text.orderlist': 'Manage Orders',
  'text.openorders': 'Open Orders',
  'text.closedorders': 'Closed Orders',
  'action.viewReceipt': 'View Receipt',
  // 'text.tables': 'Tables',
  'action.viewDetails': 'View Details',
  'text.orderinfo': 'Order Summary',
  'text.ordercreatedon': 'Created',
  'text.ordertype': 'Order Type',
  'text.ordertableno': 'Table Number',
  'text.orderseatno': 'Seat Number',
  'text.orderpaymentstatus': 'Payment Status',
  'text.ordertransactiontype': 'Transaction Type',
  'text.orderpaymentmethod': 'Payment Method',
  'text.orderauthnumber': 'Auth Number',
  'text.ordertransactiondate': 'Transaction Date/Time',
  'text.orderstatus': 'Order Status',
  'text.space': '{count, plural, =1{1} other{#}}',
  // 'text.totalCount': '{count, plural, =1{1} other{#}}',
  'text.tables': '{count, plural, =1{1 Table} other{# Tables}}',

  'text.order.open': 'Order Open',
  'text.order.confirmed': 'Order Confirmed',
  'text.order.processing': 'Order Processing',
  'text.order.cancelled': 'Order Cancelled',
  'text.order.completed': 'Order Completed',
  'text.orderusername': 'User',
  'text.ordertip': 'Tip',
  'text.orderamountdue': 'Amount Due',
  'text.orderamountpaidwithtip': 'Amount Paid (Incl.tip)',
  'text.orderamountpaid': 'Amount Paid',
  'text.orderno': 'Order#',

  // Open Order Table Header

  'text.openorder.sno': 'S.No.',
  'text.openorder.orderno': 'Order Number',
  'text.openorder.ordertype': 'Order Type',
  'text.openorder.tableno': 'Table Number',
  'text.openorder.seatno': 'Seat Number',
  'text.openorder.servername': 'Server Name',
  'text.openorder.ordertotal': 'Order Total',
  'text.openorder.paymentstatus': 'Payment Status',
  'text.openorder.order_status': 'Order Status',
  'text.openorder.orderinfo': 'Order Info',
  'text.openorder.orderdetail': 'Order Detail',
  'text.openorder.user': 'User',
  'text.openorder.search': 'Search by order number or user',

  // transaction history
  'form.placeholder.orderno': 'Order Number',
  'form.placeholder.ordertype': 'Order Type',
  'form.placeholder.ordertotal': 'Order Total',
  'form.placeholder.payment_status': 'Payment Status',
  'form.placeholder.transactiontype': 'Transaction Type',
  'form.placeholder.user': 'User',
  'form.placeholder.tablenumber': 'Table Number',
  'form.placeholder.seat_number': 'Seat Number',
  'form.placeholder.created': 'Order Created Time',

  // Close Order Table Header

  'text.closeorder.sno': 'S.No.',
  'text.closeorder.orderno': 'Order Number',
  'text.closeorder.ordertype': 'Order Type',
  'text.closeorder.tableno': 'Table Number',
  'text.closeorder.seatno': 'Seat Number',
  'text.closeorder.servername': 'Server Name',
  'text.closeorder.ordertotal': 'Order Total',
  'text.closeorder.orderdetail': 'Order Detail',
  'text.closeorder.paymentstatus': 'Payment Status',
  'text.closeorder.search': 'Search by order number or user',
  'text.closeorder.user': 'User',

  // *******************************Store Settings*******************************/

  'text.storedetails': 'Store Details',
  'text.storesettings': 'Store Info',
  'form.label.storeName': 'Store Name',
  'form.error.storeName.required': 'Please input the store name',
  'form.placeholder.storeName': 'Enter the store name',
  'form.label.storetype': 'Store Type',
  'form.error.storetype.required': 'Please input the store type',
  'form.placeholder.storetype': 'Enter the store type',
  'form.label.address': 'Address',
  'form.error.address.required': 'Please input the address',
  'form.placeholder.address': 'Enter the address',
  'form.label.city': 'City',
  'form.error.city.required': 'Please enter the city',
  'form.placeholder.city': 'Enter the city',
  'form.placeholder.country': 'Enter the Country',
  'form.label.province': 'Province/State',
  'form.placeholder.province': 'Enter the province/state',
  'form.error.province.required': 'Please select the province/state',
  'form.label.selectprovince': 'Select Province/State',
  'form.label.postalCode': 'Zip/Postal Code',
  'form.error.postalCode.required': 'Please input the Zip/Postal code',
  'form.placeholder.postalCode': 'Enter the Zip/Postal code',
  'form.label.country': 'Country',
  'form.error.country.required': 'Please select the country',
  'form.label.selectcountry': 'Select Country',
  'text.contactdetails': 'Contact Details',
  'form.label.email': 'Email',
  'form.error.email.required': 'Please input the email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 25 characters',
  'form.placeholder.email': 'Enter the email',
  'form.label.phone_number': 'Phone Number',
  'form.label.phone_number.optional': 'Phone Number (Optional)',
  'form.error.phone_number.required': 'Please input the phone number',
  'form.placeholder.phone_number': '(123) 456-7890',
  'form.placeholder.phone': '(123) 456-7890',
  'form.label.alternate_phone_number': 'Alt. Phone Number',
  'form.error.alternate_phone_number.required': 'Please input the alternate phone number',
  'form.placeholder.alternate_phone_number': '(123) 456 7890',
  'text.preferences': 'Preferences',
  'form.label.language': 'Prefered Language',
  'form.error.language.required': 'Please select the prefered language',
  'form.placeholder.language': 'Select the prefered language',
  'form.label.timezone': 'Time Zone',
  'form.error.timezone.required': 'Please select the time zone',
  'form.placeholder.timezone': 'Select the time zone',
  'text.businessdetails': 'Business Details',
  'form.label.businessid': 'Business ID',
  'form.label.locationid': 'Location ID',
  'form.placeholder.businessid': 'Enter the 4-digit Business ID',
  'form.placeholder.locationid': 'Enter the 4-digit Location ID',

  // *******************************Manage Staff*******************************/

  'text.stafflist': 'Manage Users',
  'text.staffDetails': 'User Info',
  'text.removeStaff': 'Remove User Info',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'action.createstaff': 'Create User',
  'text.createstaff': 'Create User',
  'text.editstaff': 'Update User',
  'text.active': 'Active',
  'text.inactive': 'Inactive',
  'form.label.active': 'Status',
  'text.edit': 'Edit',
  'form.label.firstName': 'First Name',
  'form.error.firstName.required': 'Please input the first name',
  'form.error.firstName.validation':
    'Please input the first name without special characters or space',
  'form.error.firstName.max': 'First name must be up to 20 characters',
  'form.placeholder.firstName': 'Enter the first name',
  'form.label.contactname': 'Contact Name',
  'form.error.contactname.required': 'Please input the Contact name',
  'form.error.contactname.max': 'Contact name must be up to 20 characters',
  'form.placeholder.contactname': 'Enter the Contact name',
  'form.label.name': 'Contact Name',
  'form.error.name.required': 'Please input the name',
  'form.invalid.name.required': 'Please input the name without special characters or space',
  'form.error.name.max': 'name must be up to 20 characters',
  'form.placeholder.name': 'Enter the name',
  'form.label.lastName': 'Last Name',
  'form.error.lastName.required': 'Please input the last name',
  'form.error.lastName.validation':
    'Please input the last name without special characters or space',
  'form.error.lastName.max': 'Last name must be up to 20 characters',
  'form.placeholder.lastName': 'Enter the last name',
  'form.label.role': 'Role',
  'form.error.role.required': 'Please select the role',
  'form.placeholder.role': 'Select the role',
  'form.label.password': 'Password',
  'form.user.label.password': 'Password',
  'form.user.label.password.optional': 'Password (Optional)',
  'form.error.password.required': 'Please input the password',
  'form.placeholder.password': 'Enter the password',
  'form.label.repassword': 'Re-Type Password',
  'form.error.repassword.required': 'Please re-type your password',
  'form.placeholder.repassword': 'Re-Type your password',
  'form.label.loginPin': 'Login Pin',
  'form.error.loginpin.length': 'Login Pin must be in 4 digits',
  'form.error.loginpin.required': 'Please enter 4 digit login pin',
  'form.placeholder.loginPin': 'Enter the 4-digit login pin',
  'form.label.retypeloginpin': 'Re-Type Login Pin',
  'form.error.retypeloginpin.required': 'Please re-enter 4 digit login pin',
  'form.placeholder.retypeloginpin': 'Enter the 4-digit login pin again',
  'text.removeuser.description': 'Are you sure you want to remove the user?',
  'form.user.placeholder.search': 'Search by name or email',
  'form.label.email.optional': 'Email (Optional)',

  // View Staff Table Header

  'text.viewstaff.sno': 'S.No.',
  'text.viewstaff.name': 'Name',
  'text.viewstaff.email': 'Email',
  'text.viewstaff.phone_number': 'Phone Number',
  'text.viewstaff.role': 'Role',
  'text.viewstaff.status': 'Status',
  'text.viewstaff.action': 'Action',
  'text.removeUser': 'Remove {name}',

  // *******************************Table Pay Settings*******************************/

  'text.deviceinfo.Settings': 'Settings',
  'text.tablepaysettings': 'Table Pay Settings',
  'text.receiptsettings': 'Receipt Settings',
  'form.label.idletimeout': 'Idle Timeout (In seconds)',
  'form.error.idletimeout.required': 'Idle Timeout required',
  'form.placeholder.idletimeout': '0 means disabled',
  'form.label.tipamount': 'Tip Options',
  'form.label.tip': 'Tip',
  'form.label.tipoptions': 'Choose exactly 3 tip options',
  'form.label.giftcard': 'Do you want to enable Gift Card Option?',
  'form.label.printreceipt': 'Print receipt after the order is completed?',
  'form.label.printmerchantcopy': 'Print merchant copy after the order is completed?',
  'form.label.footerline': 'Footer Line Text',
  'form.error.footerline.required': 'Please enter the text',
  'form.placeholder.footerline': 'Please enter the footer line text',
  'text.deviceinfo.version': 'Version',
  'text.errorlogsmessage': 'No Error Logs to show',

  // *******************************Device Info*******************************/

  'form.error.businessid.max': 'Please enter business id max of 8 digits',
  'form.error.businessid.required': 'Please enter Business id',
  'form.error.locationid.required': 'Please enter Location id',
  'text.deviceinfo': 'Device Info',
  'text.deviceinfo.windowsclient': 'Windows Client',
  'text.deviceinfo.paymentterminal': 'Payment Terminal',

  // Device Info

  'text.deviceinfo.sno': 'S.No.',
  'text.deviceinfo.devicename': 'Device Name',
  'text.deviceinfo.ipaddress': 'IP Address',
  'text.deviceinfo.macaddress': 'MAC Address',
  'text.deviceinfo.lastactivity': 'Last Activity',
  'text.deviceinfo.networkstatus': 'Network Status',
  'text.deviceinfo.logs': 'Logs',
  'text.deviceinfo.devicetype': 'Device Type',
  'text.deviceinfo.viewlogs': 'View Logs',
  'text.devicelogs': 'Device Logs',
  'text.deviceinfo.servicestatus': 'Service Status',
  'text.deviceinfo.servicestatusrunning': 'Running',
  'text.deviceinfo.servicestatusstopped': 'Stopped',
  'form.paymentterminal.placeholder.search': 'Search by device name',
  'form.windowsclient.placeholder.search': 'Search by device name',

  // Helmet Title
  'title.helmet.devicelogs': 'Device Info',
  'title.helmet.orders': 'Manage Orders',
  'title.helmet.createstaff': 'Create User',
  'title.helmet.updatestaff': 'Update User',
  'title.helmet.viewstaff': 'Manage Users',
  'title.helmet.store': 'Store Info',
  'title.helmet.tablepaysettings': 'Settings',
  'title.helmet.orderinfo': 'Order Info',

  'action.viewlogs': 'View Logs',

  // General Language Options
  'form.placeholder.search': 'Search..',
  'action.save': 'Save',
  'action.submit': 'Submit',
  'action.view': 'View Logs',
  'action.update': 'Update',
  'action.create': 'Create',
  'action.yes': 'Yes',
  'action.no': 'No',
  'text.enable': 'Enable',
  'text.disable': 'Disable',
  'action.enable': 'Enable',
  'action.edit': 'Edit',
  'action.remove': 'Remove',
  'action.disable': 'Disable',
  'text.online': 'Online',
  'action.offline': 'Offline',
  'action.signIn': 'Sign in',
  'action.previous': 'Back',
  'action.forgotPassword': 'Forgot password?',
  'action.signUp': 'Sign Up',
  'action.next': 'Next',
  'action.continue': 'Continue',
  'action.createaccount': 'Create account',
  'action.gotosignin': 'Go to Sign in',
  'action.Signin': 'Sign in',
  'text.alreadyHaveAnAccount': 'Already have an account?',

  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.user': 'User : ',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone Number',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'text.storedetail.discount': 'Discount',
  'text.storedetail.AccountStatus': 'Status',
  'action.storedetail.update': 'Update',
  'form.label.cashdiscount': 'Cash Discount',
  'form.label.discountValue': 'Cash Discount Value',
  'form.error.discountValue': 'Enter the number which is greater than zero',
  'text.copyright': 'Copyright © {copyrighttext}. All rights reserved.',
  'text.copyrightInnerpage': 'Copyright © {copyrighttext}.',
  'form.label.restaurant_software': 'Restaurant Software',
  'form.placeholder.restaurant_software': 'Restaurant Software',

  // *******************************Login*******************************/

  'text.signIn.title': 'Sign in to your Table Pay account',
  'text.dontHaveAccount': "Don't have an account?",

  // *******************************Signup*******************************/

  'text.stepper.ownerProfile': 'Owner Profile',
  'text.stepper.businessProfile': 'Business Profile',
  'title.register': 'Register',
  'text.ownerProfile.title': 'Owner Profile',
  'text.ownerProfile.description':
    'Tell us about the owner of the business to start your application.',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.max': 'Password must be up to 10 characters',
  'form.label.agentid': 'Agent ID (optional)',
  'form.placeholder.agentid': 'Enter the agent ID',
  'form.error.agentid.required': 'Please enter the Agent ID',
  'text.businessProfile.title': 'Business Profile',
  'text.possoftware.title': 'Restaurant Software',
  'text.businessProfile.description':
    'Tell us about your business so we can help you set it up to accept payments.',
  'form.label.businesstype': 'Business Type',
  'form.label.businessName': 'Business Name',
  'form.error.businessName.required': 'Please input the business name',
  'form.error.businessName.max': 'Business name must be up to 64 characters',
  'form.placeholder.businessName': 'Enter the business name',
  'form.label.businessAddress': 'Business Address',
  'form.error.businessAddress.required': 'Please input the business address',
  'form.error.businessAddress.max': 'Business address must be up to 180 characters',
  'form.placeholder.businessAddress': 'Street address',
  'form.error.businesscity.required': 'Please input the business city',
  'form.error.businessprovince.required': 'Please select the business province/state',
  'form.error.businesscountry.required': 'Please select the business country',
  'form.error.businesspostalcode.required': 'Please input the business Zip/Postal code',
  'form.error.businesstype.required': 'Please select the business type',
  'form.error.empty.select': 'No matches found.',
  'form.label.emailaddress': 'Email Address',
  'form.placeholder.emailaddress': 'Email Address',
  'text.stacktrace': 'Stacktrace',
  'text.message': 'Message',
  'text.resetpassword': 'Recover Password',
  'text.resetbutton': 'Reset',
  'title.recoverPassword': 'Recover Password',
  'action.recoverPassword': 'Recover my password',
  'title.resetPassword': 'Reset Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'action.resetPassword': 'Reset my password',
  'form.error.password.match': "Passwords doesn't match",
  'form.label.select.restaurant_software': 'Select Restaurant Software from list',
  'form.error.restaurant_software.required': 'Please select the Restaurant Software',
  'form.error.phone.invalid': 'Invalid phone number',
  'text.custommessage.openorder': 'No open orders to show',
  'text.custommessage.closedorder': 'No closed orders to show',
  'text.custommessage.windowsclient': 'No windows client to show',
  'text.custommessage.paymentterminal': 'No payment terminal to show',
  'text.custommessage.activestores': 'No active stores to show',
  'text.custommessage.inactivestores': 'No Inactive stores to show',
  'text.custommessage.demostores': 'No Demo account to show',
  'text.custommessage.userinfo': 'No users to show',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
