/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  data: null,
  data17: null,
  data18: null,
  updating: false,
  errors: {
    create: null,
    update: null,
  },
}

export default function BusinessReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
