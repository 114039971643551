/* eslint-disable camelcase */
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { loadAll, update_timezone, updateStore } from 'api/business'
import actions from './actions'

export function* LOAD_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
    },
  })

  const { response } = yield call(loadAll)
  if (response) {
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data1: businesses[0].name,
        data2: businesses[0].type,
        data4: businesses[0].locations.address,
        data5: businesses[0].locations.city,
        data6: businesses[0].locations.province,
        data7: businesses[0].locations.postal_code,
        data8: businesses[0].locations.country,
        data10: businesses[0].user.first_name,
        data11: businesses[0].user.last_name,
        data12: businesses[0].user.email,
        data13: businesses[0].user.phone_number,
        data14: businesses[0].time_zone,
        data15: businesses[0].locations.contact.alt_phone_number,
        data16: businesses[0].partner.restaurant_software,
        data17: businesses[0]?.is_charge_discount,
        data18: businesses[0]?.charge_discount_value,
      },
    })
  }
}

export function* UPDATE_STORE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response, error } = yield call(updateStore, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    console.log('response', response)
    yield put({
      type: actions.SET_STATE,
      payload: {
        data17: response?.data?.data?.business?.is_charge_discount,
        data18: response?.data?.data?.business?.charge_discount_value,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        update: errors,
      },
    })
  }
}

export function* UPDATE_TIMEZONE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  yield call(update_timezone, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  yield call(LOAD_ALL)
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.UPDATE_TIMEZONE, UPDATE_TIMEZONE),
    takeEvery(actions.UPDATE_STORE, UPDATE_STORE),
  ])
}
